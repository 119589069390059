import React from "react";
import Logo from "../icons/GlobalConsent";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    height: "200px",
    width: "200px",
    pointerEvents: "none",
    animation: `$loadEffect 2000ms ${theme.transitions.easing.easeInOut}`
  },
  "@keyframes loadEffect": {
    "0%": {
      opacity: 0,
      transform: "rotate(-90deg)"
    },
    "3%": {
      opacity: 1,
      transform: "rotate(-90deg)"
    },
    "100%": {
      transform: "rotate(0deg)"
    }
  }
}));

export default function Loading({ children }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Logo width="100%" />
    </div>
  );
}
