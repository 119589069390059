import React from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

export default function Alert({ children }) {
  return (
    <Container maxWidth="md" style={{ textAlign: "center" }}>
      {children}
      <div>
        <Button
          variant="contained"
          component={Link}
          underline="none"
          href="https://dashboard.globalconsent.com"
        >
          Return to Dashboard
        </Button>
      </div>
    </Container>
  );
}
