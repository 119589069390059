import React from "react";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Loading from "./Loading";
export default function Confirm() {
  const [state, setState] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setState(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  window.location.replace("https://dashboard.globalconsent.com");

  return (
    <div>
      <Loading />
      {state && (
        <>
          <Typography variant="h1">Success!</Typography>
          <Button
            variant="contained"
            component={Link}
            underline="none"
            href="https://dashboard.globalconsent.com"
          >
            Continue to the Dashboard
          </Button>
        </>
      )}
    </div>
  );
}
