import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2)
  }
}));

const FormikTextField = ({
  formik,
  name,
  autoComplete,
  label,
  variant,
  type
}) => {
  const classes = useStyles();
  const isInError = !!formik.touched[name] && !!formik.errors[name];
  return (
    <div className={classes.root}>
      <FormControl fullWidth>
        <TextField
          id={name}
          name={name}
          error={isInError}
          variant="outlined"
          fullWidth
          autoComplete={autoComplete}
          label={"password"}
          type={type || "text"}
          multiline={variant === "multiline"}
          rows={variant === "multiline" ? 5 : null}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[name]}
        />
      </FormControl>
      {isInError ? (
        <FormHelperText htmlFor={name} id={`label-${name}`}>
          password invalid
        </FormHelperText>
      ) : null}
    </div>
  );
};
export default FormikTextField;
