import React from "react";
import firebase from "firebase/app";
import { useAsync } from "react-async-hook";
import Alert from "../components/Alert";
import Loading from "../components/Loading";
import Confirm from "../components/Confirm";

export default function VerifyEmail({ oobCode }) {
  const verifier = useAsync(
    async code => await firebase.auth().applyActionCode(code),
    [oobCode]
  );
  if (verifier.loading) return <Loading />;
  if (verifier.error) return <Alert>{verifier.error.message}</Alert>;
  return <Confirm />;
}
