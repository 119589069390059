import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { medical as theme } from "./theme";
import Alert from "./components/Alert";
import PasswordResetter from "./routes/PasswordResetter";
import VerifyEmail from "./routes/VerifyEmail";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import qs from "qs";
const useStyles = makeStyles(theme => ({
  root: {
    textAlign: "center",
    backgroundColor: "#FFF",
    minHeight: " 100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.4rem",
    color: "#333"
  }
}));

function App() {
  const classes = useStyles();

  const { mode, oobCode } = qs.parse(document.location.search.replace("?", ""));
  const error = !mode || !oobCode ? "Misformed Code" : null;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={classes.root}>
        {error && <Alert>{error}</Alert>}
        {mode === "verifyEmail" && <VerifyEmail oobCode={oobCode} />}
        {mode === "resetPassword" && <PasswordResetter oobCode={oobCode} />}
      </div>
    </ThemeProvider>
  );
}

export default App;
