import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
// e6332a red
// 58b031 dental green
// f4a7be pink
// de9503 orange

/**
 * The GC medical theme
 */
export const medical = responsiveFontSizes(
  createMuiTheme({
    typography: {
      fontFamily: "proxima-nova, sans-serif",

      h1: {
        fontSize: "2rem",
        fontWeight: 600,
        color: "#1a1f36"
      },
      h2: {
        fontSize: "1.4rem",
        fontWeight: 500,
        color: "#1a1f36"
      },
      h3: {
        fontSize: "1.4rem",
        fontWeight: 500,
        color: "#1a1f36"
      },
      h4: {
        fontSize: "1.4rem",
        color: "#1a1f36"
      },
      h5: {
        fontSize: "1.2rem",
        color: "#1a1f36"
      },
      h6: {
        fontSize: "1.1rem",
        color: "#1a1f36"
      },
      subtitle1: {
        fontSize: "1.8rem",
        fontWeight: 600,
        opacity: 0.9,
        color: "#1a1f36"
      },
      subtitle2: {
        fontSize: "1.6rem",
        fontWeight: 600,
        opacity: 0.86,
        color: "#1a1f36"
      },
      body1: {
        fontWeight: 500,
        fontSize: "1.2rem",
        color: "#1a1f36"
      },
      body2: {
        fontSize: "1.09rem",
        color: "#1a1f36"
      },
      button: {
        textTransform: "normal",
        color: "#1a1f36",
        fontSize: "1.09rem",
        fontWeight: 600
      },
      caption: {
        fontSize: "1.09rem",
        color: "#1a1f36"
      },
      overline: {
        color: "#1a1f36"
      }
    },
    shape: {
      borderRadius: 3
    },
    palette: {
      text: {
        default: "#1a1f36"
      },
      primary: {
        main: "#388CD5",
        accent: "#28a745"
      },
      secondary: {
        main: "#3E4553"
      },
      background: {
        // default: 'rgb(227, 232, 238)',
        default: "#f5f5fa", //"#e3e8ee", // '#f6f8fa',
        light: "#f7fafc",
        mid: "#F5F4F6",
        dark: "#3B4250"
      }
    }
  })
);

// 3c4257
