import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "../components/FormikTextField";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import firebase from "firebase/app";
import Link from "@material-ui/core/Link";
import Loading from "../components/Loading";

/**
 * Used to sign up and sign in
 */
export const authSchema = Yup.object().shape({
  password: Yup.string()
    .required("required")
    .min(8, "min")
});

const useStyles = makeStyles(theme => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    marginBottom: theme.spacing(3)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    flexDirection: "column",
    color: "#fff"
  }
}));

/**
 * Login form for admin users
 */
export default function PasswordResetter({ oobCode }) {
  const classes = useStyles();
  const [state, setState] = React.useState(null);

  const formik = useFormik({
    initialValues: {
      password: ""
    },
    validationSchema: authSchema,
    onSubmit: (values, { setSubmitting, setFieldError }) => {
      setSubmitting(true);
      firebase
        .auth()
        .confirmPasswordReset(oobCode, values.password)
        .then(() => {
          setState("success");
          setSubmitting(false);
        })
        .catch(error => {
          setFieldError("general", error.message);
          setSubmitting(false);
        });
    }
  });

  if (state)
    return (
      <Container maxWidth="sm">
        <Typography variant="h1">Password reset!</Typography>
        <Button
          variant="contained"
          component={Link}
          underline="none"
          href="https://dashboard.globalconsent.com"
        >
          Continue to Sign in
        </Button>
      </Container>
    );

  return (
    <Container maxWidth="sm">
      <Typography variant="h1">New Password</Typography>

      <form className={classes.form} onSubmit={formik.handleSubmit}>
        {formik.errors && formik.errors.general && (
          <div>{formik.errors.general}</div>
        )}
        <TextField
          formik={formik}
          label="password"
          name="password"
          type="password"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={formik.isSubmitting}
          className={classes.submit}
        >
          Set new Password
        </Button>
        <Backdrop
          className={classes.backdrop}
          open={formik.isSubmitting}
          onClick={() => {}}
        >
          <Loading />
        </Backdrop>
      </form>
    </Container>
  );
}
